import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "./navb.css";
// import logo1 from "./logo1.png";
// import logo9 from "./logo9.png";
// import logonew from "./logonew.png";
import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import { useContext } from "react";

function Navb() {
  const [isSticky, setSticky] = useState(false);
  const { user } = useContext(AuthContext);
  if (user) {
    const pay = user.payment;
  }
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");

      // Check if the navbar element exists before proceeding
      if (navbar) {
        const sticky = navbar.offsetTop + 40;
        // console.log("sticky",sticky);
        // console.log("windowscrollY",window.scrollY);
        setSticky(window.scrollY > sticky);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  // console.log("path", path);

  return (
    // <div className="navbartop">
    <div className={`navbartop ${isSticky ? "sticky" : ""}`} id="navbar">
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        {/* <Container> */}
        {/* <Navbar.Brand href="/">Logo</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {path ? (
              <Nav.Link href="/" className="text-white">
                Home
              </Nav.Link>
            ) : (
              <Nav.Link href="#home" className="text-white">
                Home
              </Nav.Link>
            )}
            {/* <Nav.Link href="#home">home</Nav.Link> */}

            <NavDropdown
              title="Course"
              className="white"
              id="collapsible-nav-dropdown"
              // alignRight
            >
              {/* <NavDropdown.Item href="#action/3.1">CBSC</NavDropdown.Item> */}

              <NavDropdown.Item href="#action/3.2">
                <DropdownSubmenu
                  href="#action/3.7"
                  lassName="subControl"
                  title="CBSE"
                >
                  {/* <NavDropdown.Item href="#action/8.1">
                      Grade 1
                    </NavDropdown.Item> */}

                  {/* <DropdownSubmenu href="#view/3.7" title="Grade 1">
                      <NavDropdown.Item href="/view/gr1sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr1mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu> */}

                  {/* <DropdownSubmenu href="#action/3.7" title="Grade 2">
                      <NavDropdown.Item href="/view/gr2sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr2mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu> */}
                  {/* <DropdownSubmenu href="#action/3.7" title="Grade 3">
                      <NavDropdown.Item href="/view">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/9.2">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu> */}
                  {/* <DropdownSubmenu href="#action/3.7" title="Grade 4">
                      <NavDropdown.Item href="#action/9.1">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/9.2">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu> */}
                  {/* <DropdownSubmenu href="#action/3.7" title="Grade 5">
                      <NavDropdown.Item href="#action/9.1">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/9.2">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu> */}
                  <DropdownSubmenu href="#action/3.7" title="Grade 6">
                    <NavDropdown.Item href="/view/gr6sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/gr6mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 7">
                    <NavDropdown.Item href="/view/gr7sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/gr7mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 8">
                    <NavDropdown.Item href="/view/gr8sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/gr8mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 9">
                    <NavDropdown.Item href="/view/gr9sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/gr9mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 10">
                    <NavDropdown.Item href="/view/gr10sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/gr10mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </DropdownSubmenu>
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.1">ISC</NavDropdown.Item> */}

              <NavDropdown.Item href="#action/3.1">
                <DropdownSubmenu
                  href="#action/3.7"
                  lassName="subControl"
                  title="ICSE"
                >
                  <DropdownSubmenu href="#action/3.7" title="Grade 6">
                    <NavDropdown.Item href="/view/icse6sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/icse6mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 7">
                    <NavDropdown.Item href="/view/icse7sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/icse7mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 8">
                    <NavDropdown.Item href="/view/icse8sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/icse8mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 9">
                    <NavDropdown.Item href="/view/icse9sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/icse9mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                  <DropdownSubmenu href="#action/3.7" title="Grade 10">
                    <NavDropdown.Item href="/view/icse10sci">
                      Science
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/icse10mat">
                      Maths
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </DropdownSubmenu>
              </NavDropdown.Item>

              {/* <NavDropdown.Item href="#action/3.3">NEET</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">IIT</NavDropdown.Item> */}
              <NavDropdown.Item href="#action/3.3">
                <DropdownSubmenu
                  href="#action/3.7"
                  className="subControl"
                  title="Compe Exam"
                >
                  <NavDropdown.Item href="#action/8.1">
                    Entrance Exams
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/8.1">
                    <DropdownSubmenu href="#action/3.7" title="Banking Exams">
                      <NavDropdown.Item href="#/view/icse10sci">
                        Bank Probationary Officer (PO) Exams
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#/view/icse10mat">
                        Clerical Exam
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#/view/icse10mat">
                        Specialist Officer (SO) Exams
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#/view/icse10mat">
                        Regional Rural Banks (RRBs) Exams
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#/view/icse10mat">
                        Reserve Bank of India (RBI) Exams
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="#action/8.1">
                    <DropdownSubmenu href="#action/3.7" title="Railway Exams">
                      <NavDropdown.Item href="#/view/icse10sci">
                        Railway Recruitment Board (RRB) Exams
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#/view/icse10mat">
                        <DropdownSubmenu
                          href="#action/3.7"
                          title="Common Railway Exams in India"
                        >
                          <NavDropdown.Item href="#/view/icse10sci">
                            RRB NTPC
                          </NavDropdown.Item>
                          <NavDropdown.Item href="#/view/icse10mat">
                            RRB Group D
                          </NavDropdown.Item>
                          <NavDropdown.Item href="#/view/icse10mat">
                            RRB JE/SSE
                          </NavDropdown.Item>
                        </DropdownSubmenu>
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/view/icse10sci">
                    Famous Competitive Exams
                  </NavDropdown.Item>
                  <DropdownSubmenu
                    href="#action/3.7"
                    title="Competitive Exams for Professional Courses"
                  >
                    <NavDropdown.Item href="#/view/icse10sci">
                      RRB NTPC
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/view/icse10mat">
                      RRB Group D
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#/view/icse10mat">
                      RRB JE/SSE
                    </NavDropdown.Item>
                  </DropdownSubmenu>
                </DropdownSubmenu>
              </NavDropdown.Item>
            </NavDropdown>
            {path ? (
              <Nav.Link href="/#about" className="text-white">
                About Us
              </Nav.Link>
            ) : (
              <Nav.Link href="#about" className="text-white">
                About Us
              </Nav.Link>
            )}
            <Nav.Link href="/contact" className="text-white">
                Contact Us
              </Nav.Link>
            {/* <Nav.Link href="#about">About Us</Nav.Link> */}
            {/* {path ? (
              <Nav.Link href="/#contact" className="text-white">
                Contact Us
              </Nav.Link>
            ) : (
              <Nav.Link href="#contact" className="text-white">
                Contact Us
              </Nav.Link>
            )} */}
            {/* <Nav.Link href="#contact">contact Us</Nav.Link> */}
          </Nav>
          <Nav className="littleGap">
            {path != "register" ? (
              <Nav.Link className="buttn" href="/register">
                Register
              </Nav.Link>
            ) : (
              ""
            )}

            {path != "login" ? (
              user && user.payment === true ? (
                <Nav.Link className="buttn" href="/dashboard">
                  Go to Dashboard
                </Nav.Link>
              ) : (
                <Nav.Link className="buttn" eventKey={2} href="/login">
                  Login
                </Nav.Link>
              )
            ) : (
              ""
            )}
          </Nav>
        </Navbar.Collapse>
        {/* <Outlet /> */}
        {/* </Container> */}
      </Navbar>
    </div>
  );
}

export default Navb;
