// EmailButton.js
import React from "react";
import axios from "axios";

const EmailButton = (props) => {
  // console.log("props",props);
  const email = props.email;
  const sub = props.sub;
  const message = props.message;
  const filename = props.filename;
  const blob = props.blob;
  const sendEmail = async (email, sub, message, filename,blob) => {
    // console.log(email, 33);
    // console.log("filenamefilename", filename);
    try {
      const data = { to: email, subject: sub, message: message };
      
      // Add filename and pdf to data if they are provided
      if (filename && blob) {
        data.filename = filename;
        data.pdf = blob;
      }
      await axios.post("https://api.studentrays.com/api/send-email", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }});
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  if (props.email !== "") {
    sendEmail(email, sub, message, filename,blob);
  }
  // const handleClick = () => {
  //   sendEmail("pradumkumardubey@gmail.com", "sub", "message", "filename");
  // };

  // return (
  //   <button onClick={handleClick} className="btn btn-primary">
  //     Send Email
  //   </button>
  // );
};

export default EmailButton;
