import React from "react";
import Footer from "../footer/Footer";
import DashNavbar from "../navbar/DashNavbar";
import Studybord from "../studybord/Studybord";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Button from "react-bootstrap/Button";
import "./minitopic.css";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const Minitopic = () => {
  const [matchingComptopics, setMatchingComptopics] = useState([]);
  const [matchingComptopicslen, setMatchingComptopicslen] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [minilength, setMinilength] = useState("");
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  // console.log("id", id);
  // console.log("userid", user._id);

  let { data: userData } = useFetch(`https://api.studentrays.com/api/users/${user._id}`);
  // console.log("userData", userData);
  let { data, loading, error } = useFetch(`https://api.studentrays.com/api/details/${id}`);
  // const { data, loading, error } = useFetch(`/details/${id}`);
  // console.log("miniTopics", data.miniTopics);
  const miniTopic = data.miniTopics;

  useEffect(() => {
    if (miniTopic) {
      const length = miniTopic.length;
      setMinilength(length);
    }
  }, [miniTopic]);
  // console.log("The length of miniTopics is minilength:", minilength);

  // console.log("user comptopics", userData.comptopics);
  const comp = userData.comptopics;
  // console.log("user m", user);
  // console.log("user name", user.username);
  useEffect(() => {
    if (data.miniTopics && comp) {
      const matching = data.miniTopics.map((oneData) => {
        return comp.filter((comptopic) => comptopic.subtopicId === oneData);
      });
      const flattenedMatching = matching.flat(); // Flatten the array of arrays
      setMatchingComptopics(flattenedMatching);
    }
  }, [data.miniTopics, comp]);
  useEffect(() => {
    if (matchingComptopics) {
      const lengthc = matchingComptopics.length;
      setMatchingComptopicslen(lengthc);
    }
  }, [matchingComptopics]);
  // console.log(
  //   "The length of miniTopics is setMatchingComptopics:",
  //   matchingComptopicslen
  // );
  useEffect(() => {
    if (minilength == matchingComptopicslen) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [minilength, matchingComptopicslen]);
  // const handleButtonClick = () => {
  //   if (minilength == "") {
  //     // Disable the button
  //     setButtonDisabled(true);
  //   } else {
  //     // Enable the button
  //     setButtonDisabled(false);
  //   }
  //   // if (minilength == matchingComptopicslen) {
  //   //   // Disable the button
  //   //   setButtonDisabled(false);
  //   // } else {
  //   //   // Enable the button
  //   //   setButtonDisabled(true);
  //   // }
  // };

  // console.log("matchingComptopics", matchingComptopics);
  const miniTopicsdetail = data.miniTopics;
  return (
    <div>
      <DashNavbar />
      <div className="containerSm">
        <Link to={`/dashboard/check/${id}`}>
          {/* <button disabled={isButtonDisabled}>
            Click Me
          </button> */}
          <Button
        // className="start-exam-btn"
        disabled={isButtonDisabled}
        variant={isButtonDisabled ? "secondary" : "primary"} // Change color based on disabled state
        style={{
          all: 'unset', // Reset all existing styles
          display: 'inline-block',
          padding: '10px 20px', // Add padding
          fontWeight: 'bold', // Ensure bold text
          backgroundColor: isButtonDisabled ? '#6c757d' : '#0d6efd' , // Disable/Enable colors
          color: 'white', // Text color
          borderRadius: '5px', // Rounded corners
          border: 'none',
          cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
          textAlign: 'center',
        }}
      >
        {isButtonDisabled ? "You are not ready for the Final Exam" : "Start Final Exam"}
      </Button>
        </Link>
        <div className="locker">
          {miniTopicsdetail &&
            miniTopicsdetail.map((oneData) =>
              matchingComptopics.some(
                (comptopic) => comptopic.subtopicId === oneData
              ) ? (
                <Studybord
                  key={oneData}
                  show={true}
                  miniTopicid={id}
                  id={oneData}
                />
              ) : (
                <Studybord
                  key={oneData}
                  show={false}
                  miniTopicid={id}
                  id={oneData}
                />
              )
            )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Minitopic;
