import React from "react";
import useFetch from "../../../hooks/useFetch";
import Details from "./Details";
import "./teacherStudentData.scss";
const ChapterDetails = (props) => {
  const { data: chapters } = useFetch(`https://api.studentrays.com/api/chapters/${props.id}`);
  // console.log("chapters", chapters);
  // console.log("prop", props.id);
  const { details } = chapters;
  return (
    <div className="chapter" >
      <div>
        <span className="chapterTopic bold">Chapter: </span>
        <span className="chaptername">{chapters.chapterName}</span>
      </div>
      <div style={{ display: "flex", "flex-direction": "column" }}>
        {details && details.map((id, index) => <Details key={index} id={id} compdata={props.compdata} scoredata={props.scoredata}/>)}
      </div>
    </div>
  );
};

export default ChapterDetails;
