// import { Player } from "video-react";
// import useFetch from "../../../hooks/useFetch";
// // import YouTubeVideo from "./YouTubeVideo";
// import VideoPlayer from "./VideoPlayer";
// import Questionnaire from "./Questionnaire";
// import video from "./video.mp4";
// import "./studyTable.css";
// import Button from "react-bootstrap/Button";
// import React, { useState } from "react";
// import { AuthContext } from "../../../context/AuthContext";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useContext } from "react";

// const StudyTable = (props) => {
//   const location = useLocation();
//   const id = location.pathname.split("/")[4];
//   const miniTopicid = location.pathname.split("/")[5];
//   const { data, loading, error } = useFetch(`https://api.studentrays.com/api/subtopics/${id}`);
//   console.log("studytable data", data);
//   console.log("studytable data question", data.questions);
//   // console.log("currentid",id);
//   console.log("miniTopicidin", miniTopicid);
//   const { user } = useContext(AuthContext);
//   const [showVideo, setShowVideo] = useState(true);
//   const [showQuestion, setShowQuestion] = useState(false);
//   const [submitted, setSubmitted] = useState(false);
//   const [currentQuestion, setCurrentQuestion] = useState("");
//   const [isFalse, setisFalse] = useState(true);

//   const handleVideoEnd = () => {
//     setShowVideo(false); // Hide the video
//     setShowQuestion(true);
//   };

//   const handleNext = () => {
//     setShowVideo(true); // Show the video again
//     setShowQuestion(false); // Hide the question
//   };

//   const handleSubmit = () => {
//     // You can implement your submission logic here.
//     setSubmitted(true);
//     setShowVideo(false); // Hide the video after submission
//   };

//   return (
//     <div className="videodisplay">
//       <div>
//         {isFalse && (
//           <div>
//             <h1>vedio</h1>
//             <VideoPlayer setisFalse={setisFalse} />
//           </div>
//         )}
//       </div>
//       <div>
//         {isFalse && data.questions.length === 0 ? (
//           <div>Questions not uploded yet Contact Admin</div>
//         ) : (
//           ""
//         )}
//         </div>
//         <div>
//         {!isFalse && data.questions.length > 0 ? (
//           <Questionnaire
//             myQuestion={data.questions}
//             userID={user._id}
//             miniTopicid={miniTopicid}
//             currentId={id}
//           />
//         ) : (
//           ""
//         )}
//       </div>
//     </div>
//   );
// };

// export default StudyTable;
import { Player } from "video-react";
import useFetch from "../../../hooks/useFetch";
import VideoPlayer from "./VideoPlayer";
import Questionnaire from "./Questionnaire";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import "./studyTable.css";
import Footer from "../footer/Footer";
import DashNavbar from "../navbar/DashNavbar";
const StudyTable = (props) => {
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const miniTopicid = location.pathname.split("/")[5];
  const { data, loading, error } = useFetch(
    `https://api.studentrays.com/api/subtopics/${id}`
  );
  // console.log("studytable data", data);
  const { user } = useContext(AuthContext);
  const [isFalse, setisFalse] = useState(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  // Ensure data and questions exist before accessing them
  const questionsExist = data && data.questions && data.questions.length > 0;

  return (
    <>
      <DashNavbar />
      <div className="videodisplay">
        {/* <div>
          {isFalse && (
            <div>
              <h1>Video</h1>
              <VideoPlayer setisFalse={setisFalse} />
            </div>
          )}
        </div> */}

        <div>
          {isFalse && !questionsExist && (
            <div>Questions not uploaded yet. Contact Admin.</div>
          )}
        </div>

        <div>
          {!isFalse && questionsExist && (
            <Questionnaire
              myQuestion={data.questions}
              userID={user._id}
              miniTopicid={miniTopicid}
              currentId={id}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StudyTable;
