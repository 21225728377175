// OrderButton.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import EmailButton from "../EmailButton/EmailButton";
import { jsPDF } from "jspdf";

const OrderButton = ({ username, emaildata, amount, userdata }) => {
  console.log("payusername", username);
  console.log("userdata", userdata);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [responsedata, setResponsedata] = useState();
  const [fileContent, setFileContent] = useState(null);
  const handlePayment = async () => {
    try {
      const orderResponse = await axios.post(
        "https://api.studentrays.com/api/create-order",
        {
          amount: amount * 100, // Example amount in paise (Rs. 10)
          currency: "INR",
        }
      );

      const {
        data: { id: orderId },
      } = orderResponse;

      const options = {
        key: "rzp_live_a382hbJrxwiAAi", // Replace with your Razorpay API key ID
        amount: amount * 100,
        currency: "INR",
        name: "Student Rays",
        description: "Course Enrollment",
        order_id: orderId,
        handler: async function (response) {
          console.log(response);
          setResponsedata(response);
          if (response.razorpay_payment_id) {
            // Payment success
            setSuccess(true);
            const userData = {
              username: username, // Replace with actual username
              success: true,
            };
            const paymentDetails = {
              payment: true,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              amount: amount,
              date: new Date(),
            };
            console.log("payuserData", userData);
            try {
              const payresponse = await axios.post(
                "https://api.studentrays.com/api/payment-success",
                userData
              );
              console.log("payresponse", payresponse);
              const userResponse = await axios.post(
                `https://api.studentrays.com/api/users/payment/${username}`,
                { paymentDetails }
              );
              console.log(
                "User payment updated successfully:",
                userResponse.data
              );
              navigate("/login");
            } catch (error) {
              console.error("Error sending payment success data:", error);
            }
            // try {
            //   await axios.post("/payment-success", userData);
            //   console.log("Payment success data sent to backend");
            // } catch (error) {
            //   console.error("Error sending payment success data:", error);
            // }
          }
          // Handle payment success
        },
        theme: {
          color: "#F37254", // Change theme color as per your preference
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const recieptPdf = () => {
  //   if (responsedata) {
  //     const doc = new jsPDF();
  //     doc.text(`User Name: ${username}`, 10, 10);
  //     doc.text(`Email: ${emaildata}`, 10, 20);
  //     doc.text(`Payment ID: ${responsedata.razorpay_payment_id}`, 10, 30);
  //     doc.text(`Order ID: ${responsedata.razorpay_order_id}`, 10, 40);
  //     doc.text(`Signature: ${responsedata.razorpay_signature}`, 10, 50);
  //     return doc.output('blob');
  //   }
  //   return null;
  // };

  const generatePDFReceipt = () => {
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
      height: 30,
    });
    // Add logo
    const logoWidth = 25; // Adjust the width of the logo
    const logoHeight = 25; // Adjust the height of the logo
    // doc.addImage(logo, 'PNG', 15, 12, logoWidth, logoHeight);

    doc.setFont("helvetica", "bold");
    doc.text("Student Rays", 90, 20);
    // // Reset font to normal
    // doc.setFont('helvetica', 'normal');
    // doc.setFontSize(9);
    doc.text("Course Enrollment", 80, 25);
    // doc.text('PAN No : AANAS2643F',95,30 )
    // doc.setFontSize(16);
    doc.text("Payment Reciept", 90, 40);
    doc.setFontSize(12);

    doc.text(`Reciept No:`, 20, 60);
    doc.text(`${responsedata.razorpay_payment_id}`, 80, 60);
    doc.text(`Date:`, 20, 70);
    doc.text(`${new Date().toUTCString()}`, 80, 70);
    doc.text(`Purchased By:`, 20, 80);
    doc.text(`${username}`, 80, 80);
    doc.text(`Paid Amount:`, 20, 90);
    doc.text(`Rs. ${amount}`, 80, 90);
    doc.text(`Phone Number:`, 20, 100);
    doc.text(`${userdata.phone}`, 80, 100);
    doc.text(`Email:`, 20, 110);
    doc.text(`${emaildata}`, 80, 110);
    doc.text(`State:`, 20, 120);
    doc.text(`${userdata.state}`, 80, 120);
    doc.text(`Course:`, 20, 130);
    doc.text(`${userdata.course}`, 80, 130);
    doc.text(`Grade:`, 20, 140);
    doc.text(`${userdata.grade}`, 80, 140);
    // doc.text(`Subject:                    ${subject}`, 20, 150);
    doc.text(`Section:`, 20, 150);
    doc.text(`${userdata.section}`, 80, 150);
    // doc.text(`BatchId:                   ${batch}`, 20, 170);

    // doc.setFontSize(12)
    // doc.text(`Tax exempted under section 80G(5)(iii) of Income tax vide registration No. AANAS2643FF20231`, 20, 180);
    doc.setFontSize(8);
    doc.text(
      "Please note this is an electronically generated receipt, hence does not require a signature or stamp",
      45,
      160
    );

    // // ... Add more content as needed

    return doc.output("blob"); // Output as a blob for download
  };
  // generatePDFReceipt();
  const email = emaildata; // use the email from the credentials object
  const sub = "Welcome to our app"; // customize the subject as you wish
  const message = `Hi ${username},\n\nThank you for paying to our app. We hope you enjoy our services.\n\nBest regards,\nThe app team`; // customize the message as you wish
  const filename = "reciept.pdf";
  const createblob = () => {
    const receiptData = generatePDFReceipt();
    const blob = new Blob([receiptData], { type: "application/pdf" });
    return blob;
  };
  return (
    <>
      <button onClick={handlePayment} className="btn btn-primary">
        Create Order
      </button>
      {success && (
        <EmailButton
          email={email}
          sub={sub}
          message={message}
          filename={filename}
          blob={createblob()}
        />
      )}
    </>
  );
};

export default OrderButton;
