import React from "react";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Footer from "../../assets/components/footer/Footer";
import "./complain.css";
import IMG from "./img2.jpg";
import { useState } from "react";
import ScrollButton from "../../assets/components/scroll/Scroll";
import { Helmet } from "react-helmet";
const Complain = () => {
  const [formSummit, setformSummit] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataToSend = {
      name: event.target.name.value,
      mobile: event.target.mobile.value,
      email: event.target.email.value,
      complaintext: event.target.complaintext.value,
    };
    // console.log("dataToSend", dataToSend);

    try {
      const response = await axios.post("https://api.studentrays.com/api/complains", dataToSend);
      console.log("Data sent successfully:", response.data);
      setformSummit(false);
    } catch (err) {
      console.error("complain failed:", err);
    }
  };
  return (
    <>
    <Helmet>
        <title>Complain - StudentRays</title>
        <meta name="description" content="Studentrays is an innovative educational platform" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="StudentRays" />
        <meta
          property="og:description"
          content="Studentrays is an innovative educational platform"
        />
        <meta property="og:url" content="https://www.studentrays.com/complain" />
        <link rel="canonical" href="https://www.studentrays.com/complain" />
      </Helmet>
      <div className="front" style={{ overflow: "hidden" }}>
        <div>
          <Navtop />
          <Navb />
        </div>
        <div id="complain">
          <div className="complainTop">Provide us your complain</div>
          <div className="complainArea">
            {formSummit && (
              <Form onSubmit={handleSubmit} className="complainform">
                <h3>Complain</h3>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="number"
                    name="mobile"
                    placeholder="Enter Your Mobile No."
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <Form.Label>Enter your complaintext</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={6} // Set the number of visible rows
                    maxLength={180} // Set the maximum number of characters
                    name="complaintext"
                    placeholder="Enter your complaintext (up to 180 characters)"
                  />
                </Form.Group>
                <Button type="submit">Submit</Button>
              </Form>
            )}
            {!formSummit && (
              <div className="formSummited">
                Thanks for your Feedback ,Our team will work on this
              </div>
            )}
          </div>
        </div>
        <ScrollButton/>
      </div>
      <Footer />
    </>
  );
};

export default Complain;
