import React from "react";
import useFetch from "../../../hooks/useFetch";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const MiniTopics = (props) => {
  const { data: subtopics } = useFetch(`https://api.studentrays.com/api/subtopics/${props.id}`);
  // compdata={props.compdata} scoredata={props.scoredata}
//   console.log("compdata", props.compdata);
//   console.log("scoredata", props.scoredata);
  const comp = props.compdata;
  return (
    <div className="session">
      <div>
        <span className="sessionTopic bold"> Session: </span>
        <span className="sessionname">{subtopics.topic}</span>
        {comp &&
        comp.map((data, index) =>
          data.subtopicId === subtopics._id ? (
            <span key={index}><CheckCircleIcon className="green" /></span>
          ) : (
            ""
          )
        )}
            {/* <span key={index}>no</span> */}
      </div>
      {/* <div style={{ display: "flex", "flex-direction": "column" }}>
        {miniTopics && miniTopics.map((id, index) => <MiniTopics key={index} id={id} />)}
      </div> */}
      {/* {comp &&
        comp.map((data, index) =>
          data.subtopicId === subtopics._id ? (
            <span key={index}>yes</span>
          ) : (
            <span key={index}>no</span>
          )
        )} */}
    </div>
  );
};

export default MiniTopics;
