import Carousel from "react-bootstrap/Carousel";
import Img1 from "./Photo1.jpg";
import Img2 from "./Photo2.jpg";
import Img3 from "./Photo3.jpg";
import Img4 from "./Photo4.jpg";
import Img5 from "./Photo5.jpg";
import Img6 from "./Photo6.jpg";
import Img7 from "./Photo7.jpg";
import Img8 from "./Photo8.jpg";
import Img9 from "./Photo9.jpg";
import Img10 from "./Photo10.jpg";
import Img11 from "./Photo11.jpg";
import Img12 from "./Photo12.jpg";
import Img13 from "./Photo13.jpg";
import "./carousel.css";
import React, { useState, useEffect } from "react";
function CarouselData() {
  const [carouselStyle, setCarouselStyle] = useState({
    width: "65%",
    margin: "1px auto",
  });

  useEffect(() => {
    const handleResize = () => {
      // Adjust the styles based on the window width
      if (window.innerWidth <= 1008) {
        setCarouselStyle({
          width: "90%",
          margin: "1px auto",
        });
      } else if (window.innerWidth <= 576) {
        setCarouselStyle({
          width: "100%",
          margin: "1px auto",
        });
      } else {
        // Reset to the default styles for larger screens
        setCarouselStyle({
          width: "65%",
          margin: "1px auto",
        });
      }
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial styles
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="crouselbox" id="crouselboxid">
      <Carousel style={carouselStyle}>
      {/* <Carousel style={{ width: "65%", margin: "1px auto" }} style={carouselStyle}> */}
        <Carousel.Item>
          <img src={Img5} alt="First slide" />
          <Carousel.Caption>
            <h3>Highly Qualified Tutors</h3>
            <p>
              Our team of tutors consists of experienced educators and subject
              matter experts with a proven track record of helping students
              excel in their studies
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img4} alt="Second slide" />
          <Carousel.Caption>
            <h3>Personalized Learning</h3>
            <p>
              We understand that every student is unique, so we tailor our
              teaching methods to match individual learning styles and pace,
              ensuring maximum comprehension and retention
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={Img3} alt="Third slide" />
          <Carousel.Caption>
            <h3>Interactive Live Sessions</h3>
            <p>
              Unlike many online tuition services, we offer interactive live
              sessions where students can directly engage with their tutors, ask
              questions in real-time, and receive instant feedback
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img1} alt="Forth slide" />
          <Carousel.Caption>
            <h3>Comprehensive Course Coverage</h3>
            <p>
              Our curriculum spans a wide range of subjects and levels, from
              elementary to advanced, enabling students to find the perfect fit
              for their educational needs
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={Img2} alt="Fifth slide" />
          <Carousel.Caption>
            <h3>Flexible Scheduling</h3>
            <p>
              We recognize that students have busy lives, so we offer flexible
              scheduling options, allowing them to choose the time that works
              best for their convenience
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img6} alt="Sixth slide" />
          <Carousel.Caption>
            <h3>Proven Results</h3>
            <p>
              Our success stories and testimonials speak volumes about the
              positive impact we've had on students' academic achievements. We
              have a track record of helping students not just pass exams but
              excel in them
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={Img13} alt="Seventh slide" />
          <Carousel.Caption>
            <h3>Cutting-Edge Technology</h3>
            <p>
              We leverage the latest educational technology tools and platforms
              to deliver a seamless online learning experience, making studying
              enjoyable and efficient
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img8} alt="Eighth slide" />
          <Carousel.Caption>
            <h3>Affordable Pricing</h3>
            <p>
              We believe that quality education should be accessible to all. Our
              pricing is competitive, and we offer various packages to
              accommodate different budget constraints
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={Img9} alt="Ninth slide" />
          <Carousel.Caption>
            <h3>Progress Tracking</h3>
            <p>
              We provide tools for students and parents to monitor progress,
              track performance, and set achievable goals, ensuring steady
              improvement
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img10} alt="Tenth slide" />
          <Carousel.Caption>
            <h3>Student Community</h3>
            <p>
              We foster a supportive online learning community where students
              can interact with peers, share insights, and learn
              collaboratively, enhancing their overall learning experience
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img11} alt="Eleventh slide" />
          <Carousel.Caption>
            <h3>Feedback and Improvement</h3>
            <p>
              We constantly seek feedback from students to enhance our services
              and adapt to changing educational needs, ensuring we remain at the
              forefront of online education
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img1} alt="Twelth slide" />
          <Carousel.Caption>
            <h3>Comprehensive Resources</h3>
            <p>
              In addition to live sessions, we offer an array of supplementary
              resources such as study materials, practice tests, and recorded
              sessions to reinforce learning
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Img13} alt="Thirteenth slide" />
          <Carousel.Caption>
            <h3>24/7 Customer Support</h3>
            <p>
              Our dedicated customer support team is available around the clock
              to address any queries or concerns, ensuring a smooth and
              hassle-free learning journey
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselData;
