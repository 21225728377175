// import React, { useEffect, useState } from "react";
// import Navb from "../../assets/components/nav/Navb";
// import Navtop from "../../assets/components/navtop/Navtop";
// import "./content.css";
// import Quest from "./Quest";
// import Footer from "../../assets/components/footer/Footer";
// import ScrollButton from "../../assets/components/scroll/Scroll";
// import { useLocation } from "react-router-dom";
// import {
//   gr6sci,
//   gr6mat,
//   gr7sci,
//   gr7mat,
//   gr8sci,
//   gr8mat,
//   gr9sci,
//   gr9mat,
//   gr10sci,
//   gr10mat,
//   icse6sci,
//   icse6mat,
//   icse7sci,
//   icse7mat,
//   icse8sci,
//   icse8mat,
//   icse9sci,
//   icse9mat,
//   icse10sci,
//   icse10mat,
// } from "./ContentData";

// const Content = () => {
//   const location = useLocation();
//   const gradepath = location.pathname.split("/")[2];
//   const [datas, setdatas] = useState("");
//   const [selectedQuestions, setSelectedQuestions] = useState([]);

//   useEffect(() => {
//     let content;
//     switch (gradepath) {
//       case "gr6sci":
//         content = gr6sci;
//         break;
//       case "gr6mat":
//         content = gr6mat;
//         break;
//       case "gr7sci":
//         content = gr7sci;
//         break;
//       case "gr7mat":
//         content = gr7mat;
//         break;
//       case "gr8sci":
//         content = gr8sci;
//         break;
//       case "gr8mat":
//         content = gr8mat;
//         break;
//       case "gr9sci":
//         content = gr9sci;
//         break;
//       case "gr9mat":
//         content = gr9mat;
//         break;
//       case "gr10sci":
//         content = gr10sci;
//         break;
//       case "gr10mat":
//         content = gr10mat;
//         break;
//       case "icse6sci":
//         content = icse6sci;
//         break;
//       case "icse6mat":
//         content = icse6mat;
//         break;
//       case "icse7sci":
//         content = icse7sci;
//         break;
//       case "icse7mat":
//         content = icse7mat;
//         break;
//       case "icse8sci":
//         content = icse8sci;
//         break;
//       case "icse8mat":
//         content = icse8mat;
//         break;
//       case "icse9sci":
//         content = icse9sci;
//         break;
//       case "icse9mat":
//         content = icse9mat;
//         break;
//       case "icse10sci":
//         content = icse10sci;
//         break;
//       case "icse10mat":
//         content = icse10mat;
//         break;
//       default:
//         content = "default";
//         break;
//     }

//     // Set the data
//     setdatas(content);

//     // Shuffle questions and select the first 5
//     if (content.questions) {
//       const shuffledQuestions = [...content.questions].sort(() => Math.random() - 0.5);
//       setSelectedQuestions(shuffledQuestions.slice(0, 5));
//     }
//   }, [gradepath]);

//   const redirectToRegister = () => {
//     let course = "";
//     let grade = "";
//     const gradePrefix = gradepath.substring(0, 2);
//     const gradeNumericPart = gradepath.match(/\d+/);

//     switch (gradePrefix.toLowerCase()) {
//       case "gr":
//         course = `CBSE`;
//         grade = `${gradeNumericPart}`;
//         break;
//       case "ic":
//         course = `ICSE`;
//         grade = `${gradeNumericPart}`;
//         break;
//       default:
//         break;
//     }

//     const registerURL = `/register?course=${course}&grade=${grade}`;
//     window.location.href = registerURL;
//   };

//   return (
//     <>
//       <div>
//         <Navtop />
//         <Navb />
//       </div>
//       <header className="contentHeader">
//         {datas && <h1 className="contentHeaderTopic">{datas.data}</h1>}
//         <p className="contentHeaderContent">Content to be covered</p>
//         <ul>
//           {datas && datas.list.map((item, index) => (
//             <li className="contentLi" key={index}>
//               {item.abelableContent}
//             </li>
//           ))}
//         </ul>
//       </header>
//       <div id="content">
//         <div className="data">
//           {datas && <Quest myQuestion={selectedQuestions} />}
//           <button className="registerButton" onClick={redirectToRegister}>
//             Book Free Demo
//           </button>
//         </div>
//       </div>
//       <ScrollButton />
//       <Footer />
//     </>
//   );
// };

// export default Content;
import React, { useEffect, useState } from "react";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import "./content.css";
import Quest from "./Quest";
import Footer from "../../assets/components/footer/Footer";
import ScrollButton from "../../assets/components/scroll/Scroll";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  gr6sci,
  gr6mat,
  gr7sci,
  gr7mat,
  gr8sci,
  gr8mat,
  gr9sci,
  gr9mat,
  gr10sci,
  gr10mat,
  icse6sci,
  icse6mat,
  icse7sci,
  icse7mat,
  icse8sci,
  icse8mat,
  icse9sci,
  icse9mat,
  icse10sci,
  icse10mat,
} from "./ContentData";

const Content = () => {
  const location = useLocation();
  const gradepath = location.pathname.split("/")[2];
  const [datas, setDatas] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  useEffect(() => {
    let content;
    switch (gradepath) {
      case "gr6sci":
        content = gr6sci;
        break;
      case "gr6mat":
        content = gr6mat;
        break;
      case "gr7sci":
        content = gr7sci;
        break;
      case "gr7mat":
        content = gr7mat;
        break;
      case "gr8sci":
        content = gr8sci;
        break;
      case "gr8mat":
        content = gr8mat;
        break;
      case "gr9sci":
        content = gr9sci;
        break;
      case "gr9mat":
        content = gr9mat;
        break;
      case "gr10sci":
        content = gr10sci;
        break;
      case "gr10mat":
        content = gr10mat;
        break;
      case "icse6sci":
        content = icse6sci;
        break;
      case "icse6mat":
        content = icse6mat;
        break;
      case "icse7sci":
        content = icse7sci;
        break;
      case "icse7mat":
        content = icse7mat;
        break;
      case "icse8sci":
        content = icse8sci;
        break;
      case "icse8mat":
        content = icse8mat;
        break;
      case "icse9sci":
        content = icse9sci;
        break;
      case "icse9mat":
        content = icse9mat;
        break;
      case "icse10sci":
        content = icse10sci;
        break;
      case "icse10mat":
        content = icse10mat;
        break;
      default:
        content = null;
        break;
    }

    // Set the data
    if (content) {
      setDatas(content);
      getNewQuestions(content);
    }
  }, [gradepath]);

  // Function to get a new set of questions
  const getNewQuestions = (content) => {
    if (content.questions) {
      const shuffledQuestions = [...content.questions].sort(
        () => Math.random() - 0.5
      );
      setSelectedQuestions(shuffledQuestions.slice(0, 5));
    }
  };

  // Function to handle "Try Again"
  const handleTryAgain = () => {
    getNewQuestions(datas);
  };

  const redirectToRegister = () => {
    let course = "";
    let grade = "";
    const gradePrefix = gradepath.substring(0, 2);
    const gradeNumericPart = gradepath.match(/\d+/);

    switch (gradePrefix.toLowerCase()) {
      case "gr":
        course = `CBSE`;
        grade = `${gradeNumericPart}`;
        break;
      case "ic":
        course = `ICSE`;
        grade = `${gradeNumericPart}`;
        break;
      default:
        break;
    }

    const registerURL = `/register?course=${course}&grade=${grade}`;
    window.location.href = registerURL;
  };

  return (
    <>
      <Helmet>
        <title>
          {datas.data ? `${datas.data} - StudentRays` : "StudentRays"}
        </title>
        <meta
          name="description"
          content="Studentrays is an innovative educational platform"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="StudentRays" />
        <meta
          property="og:description"
          content="Studentrays is an innovative educational platform"
        />
        <meta
          property="og:url"
          content={`https://www.studentrays.com/view/${gradepath}`}
        />
        <link
          rel="canonical"
          href={`https://www.studentrays.com/view/${gradepath}`}
        />
      </Helmet>
      <div>
        <Navtop />
        <Navb />
      </div>
      <header className="contentHeader">
        {datas && <h1 className="contentHeaderTopic">{datas.data}</h1>}
        <p className="contentHeaderContent">Content to be covered</p>
        <ul>
          {datas &&
            datas.list.map((item, index) => (
              <li className="contentLi" key={index}>
                {item.abelableContent}
              </li>
            ))}
        </ul>
      </header>
      <div id="content">
        <div className="data">
          {selectedQuestions.length > 0 && (
            <Quest myQuestion={selectedQuestions} onTryAgain={handleTryAgain} />
          )}
          <button className="registerButton" onClick={redirectToRegister}>
            Book Free Demo
          </button>
        </div>
      </div>
      <ScrollButton />
      <Footer />
    </>
  );
};

export default Content;
