import React from "react";
import Footer from "../footer/Footer";
import DashNavbar from "../navbar/DashNavbar";
import Chapterdetail from "../chapterdetail/Chapterdetail";
import useFetch from "../../../hooks/useFetch";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import "./subtopic.css";
let renderCounte = 0;
const Subtopic = () => {
  renderCounte++;
  // console.log("renderCounte", renderCounte);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { user } = useContext(AuthContext);
  const userId = user._id;
  // console.log("id", id);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/datas/find/${id}`);
  // console.log("subdata", data);
  const allData = data.chapterdetails;
  // console.log("aldata", allData);
  //   for (var i=0; i < allData.length(); i++) {
  //     console.log("1data", allData[i]);
  // }
  // const listItems = allData?.map((number) => <li>{number}</li>);

  return (
    <div>
      {/* <ul>{listItems}</ul> */}
      <DashNavbar />
      <div className="containerSm">
        <div className="subjectTitle">
          <div className="iconSubject">
            <img
              className="iconSubject"
              src="https://learn-assets.byjusweb.com/images/subjects/maths-8-abv.png"
              alt="icon"
            />
          </div>
          <div className="subjectRight">
            <div className="topicName">{data.subject}</div>
            <div className="noCount">
              <div className="chapterCount">
                <BsCheckCircle className="logoColor" />
                {data.chapterCount} chapters
              </div>
              <div className="chapterCount">
                <BsCheckCircle className="logoColor" />
                16 Journeys
              </div>
            </div>
          </div>
        </div>
        {allData &&
          allData.map((oneData) => (
            <Chapterdetail key={oneData} id={id} detailId={oneData} />
          ))}
        {/* <Chapterdetail key={index} id={data.id} detailId={e} /> */}
        {/* Subtopic */}
      </div>
      <Footer />
    </div>
  );
};

export default Subtopic;
