// // import React from 'react';
// import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

// export default function App() {
//   return (
//     <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
//       <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
//         <div className='me-5 d-none d-lg-block'>
//           <span>Get connected with us on social networks:</span>
//         </div>

//         <div>
//           <a href='' className='me-4 text-reset'>
//             <MDBIcon color='secondary' fab icon='facebook-f' />
//           </a>
//           <a href='' className='me-4 text-reset'>
//             <MDBIcon color='secondary' fab icon='twitter' />
//           </a>
//           <a href='' className='me-4 text-reset'>
//             <MDBIcon color='secondary' fab icon='google' />
//           </a>
//           <a href='' className='me-4 text-reset'>
//             <MDBIcon color='secondary' fab icon='instagram' />
//           </a>
//           <a href='' className='me-4 text-reset'>
//             <MDBIcon color='secondary' fab icon='linkedin' />
//           </a>
//           <a href='' className='me-4 text-reset'>
//             <MDBIcon color='secondary' fab icon='github' />
//           </a>
//         </div>
//       </section>

//       <section className=''>
//         <MDBContainer className='text-center text-md-start mt-5'>
//           <MDBRow className='mt-3'>
//             <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
//               <h6 className='text-uppercase fw-bold mb-4'>
//                 <MDBIcon color='secondary' icon='gem' className='me-3' />
//                 Company name
//               </h6>
//               <p>
//                 Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit
//                 amet, consectetur adipisicing elit.
//               </p>
//             </MDBCol>

//             <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
//               <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Angular
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   React
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Vue
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Laravel
//                 </a>
//               </p>
//             </MDBCol>

//             <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
//               <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Pricing
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Settings
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Orders
//                 </a>
//               </p>
//               <p>
//                 <a href='#!' className='text-reset'>
//                   Help
//                 </a>
//               </p>
//             </MDBCol>

//             <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
//               <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
//               <p>
//                 <MDBIcon color='secondary' icon='home' className='me-2' />
//                 New York, NY 10012, US
//               </p>
//               <p>
//                 <MDBIcon color='secondary' icon='envelope' className='me-3' />
//                 info@example.com
//               </p>
//               <p>
//                 <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
//               </p>
//               <p>
//                 <MDBIcon color='secondary' icon='print' className='me-3' /> + 01 234 567 89
//               </p>
//             </MDBCol>
//           </MDBRow>
//         </MDBContainer>
//       </section>

//       <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
//         © 2021 Copyright:
//         <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
//           Any Name
//         </a>
//       </div>
//     </MDBFooter>
//   );
// }
// import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  FaFacebookF,
  FaTwitter,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaGithub,
} from "react-icons/fa";
import "./footer.css";
export default function App() {
  return (
    <MDBFooter bgColor='light' className="text-center text-lg-start text-muted">
      <section className="footersection d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href="https://www.facebook.com/profile.php?id=61566225182192" target="_blank" rel="noreferrer" className="me-4 text-reset">
            <FaFacebookF color="secondary" />
          </a>
          <a href="https://x.com/studentray" target="_blank" rel="noreferrer"  className="me-4 text-reset">
            <FaTwitter color="secondary" />
          </a>
          {/* <a href="" className="me-4 text-reset">
            <FaGoogle color="secondary" />
          </a> */}
          <a href="https://www.instagram.com/studentrays/" target="_blank" rel="noreferrer"  className="me-4 text-reset">
            <FaInstagram color="secondary" />
          </a>
          {/* <a href="" className="me-4 text-reset">
            <FaLinkedin color="secondary" />
          </a>
          <a href="" className="me-4 text-reset">
            <FaGithub color="secondary" />
          </a> */}
        </div>
      </section>

      <section className="footersection">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="4" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon color="secondary" icon="gem" className="me-3" />
                Studentrays
              </h6>
              <p>
                At Studentrays, we foster a culture of values such as
                collaboration, innovation, diversity, etc. Our team of dedicated
                professionals is committed to company goals , values, ensuring
                that we not only meet but exceed expectations. Studentrays is
                deeply invested in education and community development.Through
                initiatives like educational programs, partnerships with schools
                or universities, scholarships, etc.we aim to support and nurture
                the next generation of relevant field professionals and leaders
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">COURSE OFFERINGS</h6>
              <p>LIVE Online Classes</p>
              <p>Mentored Learning Program</p>
              <p>Recorded Video Courses</p>
              <p>CBSE/ICSE</p>
              <p>Competitive Exam</p>
              <p>Crashcourses</p>
            </MDBCol>

          

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon color="secondary" icon="envelope" className="me-1" />
                Studentrays Global LLP
              </p>
              <p>
                <MDBIcon color="secondary" icon="home" className="me-1" />
                Block D-04, Flat No 404, Puravankara Apartament, Bavani Amman
                Kovil Street Pallikkaranai, Chennai, Pallikaranai, Kanchipuram,
                Tambaram, Tamil Nadu, India, 600100
              </p>
              <p>
                <MDBIcon color="secondary" icon="email" className="me-1" />
                Studentraysglobal@gmail.com
              </p>
              <p>
                <MDBIcon color="secondary" icon="phone" className="me-1" />
                +91-6385623750
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="https://www.studentrays.com">
          Student Rays
        </a>
      </div>
    </MDBFooter>
  );
}
