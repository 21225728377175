import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/login/ForgotPassword";
import Teacherlogin from "./pages/login/Teacherlogin";
import Counsellorlogin from "./pages/login/Counsellorlogin";
import MyCalendar from "./pages/Calender/calender";
import TeacherCalender from "./pages/teacherCalender/calender";
import Room from "./pages/room/Room";
import Register from "./pages/login/Register";
import Content from "./pages/content/Content";
import Session from "./pages/session/Session";
import Complain from "./pages/complain/Complain";
import Sitemap from "./pages/sitemap/Sitemap.jsx";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Dashboard from "./assets/dashboard/Dashboard";
import Paydashboard from "./assets/paydashboard/Paydashboard";
import Teacherdashboard from "./assets/teacherdashboard/Teacherdashboard";
import TeacherStudentData from "./assets/teacherdashboard/teacherStudentData/TeacherStudentData";
import Counsellordashboard from "./assets/counsellordashboard/Counsellordashboard";
import CounsellorComplain from "./assets/counsellordashboard/counsellorComplain/CounsellorComplain";
import Single from "./assets/counsellordashboard/Single";
import CounsellorCalender from "./assets/counsellordashboard/Calender/CounsellorCalender";
import CounsellorTCalender from "./assets/counsellordashboard/Calender/CounsellorTCalender";
import CounsellorCal from "./assets/counsellordashboard/Calender/CounsellorCal";
import CounsellorOneData from "./assets/counsellordashboard/counsellorOneData/CounsellorOneData";
import CounsellorFreesession from "./assets/counsellordashboard/counsellorFreesession/CounsellorFreesession";
import Subtopic from "./assets/dashboard/subtopic/Subtopic";
import Minitopic from "./assets/dashboard/minitopic/Minitopic";
import StudyTable from "./assets/dashboard/studyTable/StudyTable";
import Check from "./assets/dashboard/check/Check";
import Fixedsidebar from "./pages/fixedsidebar/Fixedsidebar";
import Contact from "./assets/components/contact/Contact";
import "./App.scss";

function App() {
  const { user } = useContext(AuthContext);
  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    if (
      user &&
      user.payment === true &&
      user.isTeacher === false &&
      user.isCounsellor === false &&
      user.isAdmin === false
    ) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
    return children;
  };

  const ProtectedPayRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    if (user && user.payment !== true) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
    return children;
  };

  const TeacherProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/teacherlogin" />;
    }
    if (
      user &&
      user.isStudent === false &&
      user.isCounsellor === false &&
      user.isAdmin === false
    ) {
      return children;
    } else {
      return <Navigate to="/teacherlogin" />;
    }
    return children;
  };

  const CounsellorProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/Counsellorlogin" />;
    }
    if (
      user &&
      user.isStudent === false &&
      user.isTeacher === false &&
      user.isAdmin === false
    ) {
      return children;
    } else {
      return <Navigate to="/Counsellorlogin" />;
    }
    return children;
  };

  return (
    <div className="app">
      <BrowserRouter>
        <Fixedsidebar />
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="view/:groID" element={<Content />} />
            <Route path="session" element={<Session />} />
            <Route path="complain" element={<Complain />} />
            <Route path="register" element={<Register />} />
            <Route path="teacherlogin" element={<Teacherlogin />} />
            <Route path="counsellorlogin" element={<Counsellorlogin />} />
            <Route path="sitemap.xml" element={<Sitemap />} />
          </Route>
          <Route path="/teacherdashboard/">
            <Route
              index
              element={
                <TeacherProtectedRoute>
                  <Teacherdashboard />
                </TeacherProtectedRoute>
              }
            />
            <Route
              path="teacherCalender/:id"
              element={
                <TeacherProtectedRoute>
                  <TeacherCalender />
                </TeacherProtectedRoute>
              }
            />
            <Route
              path=":id"
              element={
                <TeacherProtectedRoute>
                  <TeacherStudentData />
                </TeacherProtectedRoute>
              }
            />
          </Route>
          <Route path="/counsellordashboard/">
            <Route
              index
              element={
                <CounsellorProtectedRoute>
                  <Counsellordashboard />
                </CounsellorProtectedRoute>
              }
            />
            {/* <Route
                path="singleuser"
                element={
                  <CounsellorProtectedRoute>
                    <Single />
                  </CounsellorProtectedRoute>
                }
              /> */}
            <Route
              path="calender/:id"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorCalender />
                </CounsellorProtectedRoute>
              }
            />
            <Route
              path="tcalender/:id"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorTCalender />
                </CounsellorProtectedRoute>
              }
            />
            <Route
              path="counc_calender"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorCal />
                </CounsellorProtectedRoute>
              }
            />
            <Route
              path="complains"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorComplain />
                </CounsellorProtectedRoute>
              }
            />
            <Route
              path="complains/:id"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorOneData />
                </CounsellorProtectedRoute>
              }
            />
            <Route
              path="freesessions"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorFreesession />
                </CounsellorProtectedRoute>
              }
            />
            <Route
              path="freesessions/:id"
              element={
                <CounsellorProtectedRoute>
                  <CounsellorOneData />
                </CounsellorProtectedRoute>
              }
            />
          </Route>
          <Route path="/counsellordashboard/singleuser">
            <Route
              index
              element={
                <CounsellorProtectedRoute>
                  <Single />
                </CounsellorProtectedRoute>
              }
            />
          </Route>
          <Route path="/paydashboard/">
            <Route
              index
              element={
                <ProtectedPayRoute>
                  <Paydashboard />
                </ProtectedPayRoute>
              }
            />
          </Route>
          <Route path="/dashboard/">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="calender"
              element={
                <ProtectedRoute>
                  <MyCalendar />
                </ProtectedRoute>
              }
            />
            <Route
              path="Joinroom"
              element={
                <ProtectedRoute>
                  <Room />
                </ProtectedRoute>
              }
            />
            <Route
              path="topic/:id"
              element={
                <ProtectedRoute>
                  <Minitopic />
                </ProtectedRoute>
              }
            />
            <Route
              path="topic/study/:id/:miniTopicid"
              element={
                <ProtectedRoute>
                  <StudyTable />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id"
              element={
                <ProtectedRoute>
                  <Subtopic />
                </ProtectedRoute>
              }
            />
            <Route
              path="check/:id"
              element={
                <ProtectedRoute>
                  <Check />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="exam/:id"
              element={
                <ProtectedRoute>
                  <Questionnaire />
                </ProtectedRoute>
              }
            /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
