import React, { useEffect, useState } from "react";
import DashNavbar from "./navbar/DashNavbar";
import { Link } from "react-router-dom";
import { userColumns } from "./datatable/datatablesource";
import Datatable from "./datatable/Datatable";
import Sidebar from "./sidebar/Sidebar";
import "./teacherdashboard.css";
import { AuthContext } from "./../../context/AuthContext";
import { useContext } from "react";
import useFetch from "./../../hooks/useFetch";
import moment from "moment";

const Teacherdashboard = () => {
  const { user } = useContext(AuthContext);
  const { data: calenderData } = useFetch(
    `https://api.studentrays.com/api/calender/teacher/${user._id}`
  );
  // console.log("calenderData", calenderData);

  const [nextEvent, setNextEvent] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    if (calenderData && calenderData.length > 0) {
      const now = moment();

      const upcoming = calenderData
        .map((event) => {
          const eventDate = moment(event.Date);
          const [startHour, startMinute] = event.startTime
            .split(":")
            .map(Number);
          const [endHour, endMinute] = event.endTime.split(":").map(Number);

          const start = eventDate
            .clone()
            .set({ hour: startHour, minute: startMinute });
          const end = eventDate
            .clone()
            .set({ hour: endHour, minute: endMinute });

          return { ...event, start, end };
        })
        .filter((event) => event.end.isAfter(now)) // Only future events
        .sort((a, b) => a.start - b.start);

      setUpcomingEvents(upcoming);

      if (upcoming.length > 0) {
        setNextEvent(upcoming[0]); // Set the next event
      }
    }
  }, [calenderData]);

  if (!calenderData || calenderData.length === 0) {
    return <p>Loading or no events available</p>;
  }

  const now = moment();
  const isOngoing = nextEvent && now.isBetween(nextEvent.start, nextEvent.end);
  const isUpcoming = nextEvent && nextEvent.start.diff(now, "minutes") <= 15;

  // Extract unique batches
  const uniqueBatches = Array.from(
    new Set(calenderData.map((item) => item.batch))
  );
  //  console.log("uniqueBatches",uniqueBatches);
   
  return (
    <>
      <div className="fixed">
        <DashNavbar />
      </div>
      <div className="teacherflex">
        <Sidebar />
        <div className="flexdata">
          <Link to={`/teacherdashboard/teacherCalender/${user._id}`}>
            <button className="btn btn-primary">Calender</button>
          </Link>

          <div>
            {nextEvent && (isOngoing || isUpcoming) ? (
              nextEvent.batch === "councellor" ? (
                <>
                  <p>Meeting with {nextEvent.batch}</p>
                  <button
                    onClick={() => window.open(nextEvent.joinUrl, "_blank")}
                  >
                    Join meeting with Councellor
                  </button>
                </>
              ) : (
                <>
                  <p>Meeting for batch {nextEvent.batch}</p>
                  <button
                    className="btn btn-primary"
                    onClick={() => window.open(nextEvent.teacherUrl, "_blank")}
                  >
                    Join
                  </button>
                </>
              )
            ) : nextEvent ? (
              <p>Next event starts at {nextEvent.start.format("HH:mm")}</p>
            ) : (
              <p>No upcoming events</p>
            )}

            <div>
              {uniqueBatches.map((batch) => {
                const lastEvent = calenderData
                  .slice()
                  .reverse()
                  .find((calendarEvent) => calendarEvent.batch === batch);

                return (
                  <div key={batch}>
                    {batch !== "councellor" && lastEvent && (
                      <>
                        <p style={{padding:"20px 20px 0px 20px"}}></p>
                        <div>
                          <Datatable
                            key={lastEvent._id}
                            columns={userColumns}
                            teacherAssigned={lastEvent.teacherid}
                            batch={lastEvent.batch}
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teacherdashboard;
