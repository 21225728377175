// import React, { useRef } from "react";

// import { AiOutlineMail } from "react-icons/ai";
// import { FiTwitter } from "react-icons/fi";
// import { BsWhatsapp } from "react-icons/bs";
// import emailjs from "emailjs-com";
// import Navb from "../nav/Navb";
// import Navtop from "../navtop/Navtop";
// // import Form from "react-bootstrap/Form";
// import Footer from "../footer/Footer";
// import "./contact.css";
// const contactData = [
//   {
//     id: 1,
//     logo: AiOutlineMail,
//     name: "Email",
//     data: "Studentraysglobal@gmail.com",
//     datalink: "mailto:Studentraysglobal@gmail.com",
//   },
//   // {
//   //   id: 2,
//   //   logo: FiTwitter,
//   //   name: "Twitter",
//   //   data: "@PradumKumardub1",
//   //   datalink: "https://www.twitter.com/intent/tweet?screen_name=Pradum12613076",
//   // },
//   {
//     id: 3,
//     logo: BsWhatsapp,
//     name: "WhatsApp",
//     data: "+91 6385623750",
//     datalink: "https://wa.me/6385623750",
//   },
// ];

// const Contact = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_2ix9hvk",
//         "template_bid360b",
//         form.current,
//         "YxeoYNZzXmlUvA4Es"
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//     e.target.reset();
//   };
//   return (
//     <>
//       <div className="front" style={{ width: "100%" }}>
//         {/* <div> */}
//         <Navtop />
//         <Navb />
//         {/* </div> */}
//       </div>

//       <section id="contact" style={{ "padding-top": "0rem" }}>
//         <h1 className="contactHead">Get in Touch</h1>
//         {/* <h5>Get in Touch</h5> */}
//         <div className="contact_container container">
//           <div className="contact_options">
//             {contactData &&
//               contactData.map((allCard, index) => (
//                 <article key={index} className="contact_option">
//                   <allCard.logo className="contact_option_icon" />
//                   <h4>{allCard.name}</h4>
//                   <h5>{allCard.data}</h5>
//                   <a
//                     href={allCard.datalink}
//                     target="_blank"
//                     rel="noreferrer"
//                     className="btn"
//                   >
//                     Send a message
//                   </a>
//                 </article>
//               ))}
//           </div>
//           <form ref={form} className="contactForm" onSubmit={sendEmail}>
//             <input
//               type="text"
//               name="name"
//               placeholder="Your Full Name"
//               className="black"
//               required
//             />
//             <input
//               type="email"
//               name="email"
//               placeholder="Your Email"
//               required
//             />
//             <input
//               type="text"
//               name="mobile"
//               placeholder="Your Mobile no."
//               className="black"
//               required
//             />
//             <textarea
//               name="message"
//               rows="7"
//               placeholder="Your Message"
//               className="black"
//               required
//             ></textarea>
//             <button type="submit" className="btn btn-primary">
//               Send Message
//             </button>
//           </form>
//         </div>

//         <div className="map-container">
//           <iframe
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.04130897334835!2d80.19271584727458!3d12.929500050443679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d496000f6e9%3A0x4fef543466ccb120!2sE%20Block%20Purva%20Windermere!5e0!3m2!1sen!2sin!4v1724669775397!5m2!1sen!2sin"
//             allowfullscreen=""
//             loading="lazy"
//             referrerpolicy="no-referrer-when-downgrade"
//           ></iframe>
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// };

// export default Contact;
import React, { useRef, useState } from "react";
import axios from "axios";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import Navb from "../nav/Navb";
import Navtop from "../navtop/Navtop";
import Footer from "../footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./contact.css";
import { Helmet } from "react-helmet";
const contactData = [
  {
    id: 1,
    logo: AiOutlineMail,
    name: "Email",
    data: "Studentraysglobal@gmail.com",
    datalink: "mailto:Studentraysglobal@gmail.com",
  },
  {
    id: 3,
    logo: BsWhatsapp,
    name: "WhatsApp",
    data: "6369270700",
    datalink: "https://wa.me/6369270700",
  },
];

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("to", "Studentraysglobal@gmail.com");
    formDataToSend.append("subject", `New message from ${formData.name}`);
    formDataToSend.append(
      "message",
      `Name: ${formData.name}\nEmail: ${formData.email}\nMobile: ${formData.mobile}\nMessage: ${formData.message}`
    );
    if (file) {
      formDataToSend.append("pdf", file);
      formDataToSend.append("filename", file.name);
    }

    try {
      const response = await axios.post("https://api.studentrays.com/api/send-email", formDataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setStatus(response.data.message);
      setFormData({ name: "", email: "", mobile: "", message: "" });
      setFile(null);
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus("Failed to send email. Please try again.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact - StudentRays</title>
        <meta name="description" content="Studentrays is an innovative educational platform" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="StudentRays" />
        <meta
          property="og:description"
          content="Studentrays is an innovative educational platform"
        />
        <meta property="og:url" content="https://www.studentrays.com/contact" />
        <link rel="canonical" href="https://www.studentrays.com/contact" />
      </Helmet>
      <div className="front" style={{"overflow": "hidden",width: "100%"}}>
        {/* <div className="fixed"> */}
        <div>
          <Navtop />
          <Navb />
        </div>
        
      <section id="contact" className="pt-5" style={{ paddingBottom: "0rem" }}>
        <h1 className="text-center mb-4 contactHead">Get in Touch</h1>
        <div className="container">
          <div className="row">
            {/* Contact Options */}
            <div className="col-lg-6 col-md-12 mb-4">
              <div className="row">
                {contactData.map((card, index) => (
                  <div key={index} className="col-12 mb-4 contact_option">
                    <card.logo className="contact_option_icon mx-auto mb-3" />
                    <h4>{card.name}</h4>
                    <h5>{card.data}</h5>
                    <a
                      href={card.datalink}
                      target="_blank"
                      rel="noreferrer"
                      className="btn"
                    >
                      Send a Message
                    </a>
                  </div>
                ))}
              </div>
            </div>

            {/* Contact Form */}
            <div className="col-lg-6 col-md-12">
              <form
                ref={form}
                className="shadow-sm p-4 rounded bg-light contactForm"
                onSubmit={handleSubmit}
              >
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Your Mobile no."
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    name="message"
                    rows="5"
                    className="form-control"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                {/* <div className="mb-3">
                  <input
                    type="file"
                    name="pdf"
                    className="form-control"
                    accept=".pdf"
                    onChange={handleFileChange}
                  />
                </div> */}
                <button type="submit" className="btn btn-primary w-100">
                  Send Message
                </button>
              </form>
              {status && <p className="mt-3 text-center">{status}</p>}
            </div>
          </div>
        </div>

        <div className="map-container mt-5">
      
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248904.24039119572!2d80.04070198712573!3d12.89944129143938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525f1f3cbedaeb%3A0xad6d6413e8e4d638!2s259%2FE!5e0!3m2!1sen!2sin!4v1737104708991!5m2!1sen!2sin"
            className="w-100"
            // style={{ "height": "300px", "border": "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section></div>
      <Footer />
    </>
  );
};

export default Contact;
