// import React from "react";
// import "./about.css";
// import Cards from "../card/Card.js";
// import CarouselData from "../carousel/CarouselData.js";
// import { HiStatusOnline, HiUserGroup } from "react-icons/hi";
// import { RiPresentationLine } from "react-icons/ri";
// import { SiFuturelearn } from "react-icons/si";
// const About = () => {

//   return (
//     <>
// <div id="about">
//   <h1>About Us</h1>
//   <div className="selfDiscription">
//     Rays is an innovative educational platform dedicated to providing
//     personalized, expert-led online tutoring and academic support to
//     students of all ages and backgrounds, helping them achieve their
//     learning goals with confidence and success.
//   </div>
//   <div className="grayBox">
//     <div className="chooseRays">
//       <div className="seeQuestion"> Why Choose Rays?</div>
//       <div className="box">
//         <span className="boxtopic">Quality Education:</span>
//         <span className="boxContent">
//           Our courses are designed by experts and delivered by industry
//           professionals to ensure you receive a top-notch education.
//         </span>
//       </div>
//       <div className="box">
//         <span className="boxtopic">Flexible Learning:</span>
//         <span className="boxContent">
//           Learn at your own pace, on your schedule. We offer a range of
//           courses that fit your lifestyle.
//         </span>
//       </div>
//       <div className="box">
//         <span className="boxtopic">Diverse Course Catalog:</span>
//         <span className="boxContent">
//           Explore a wide variety of subjects, from arts and sciences to
//           technology and business.
//         </span>
//       </div>
//       <div className="box">
//         <span className="boxtopic">Interactive Learning:</span>
//         <span className="boxContent">
//           Engage with instructors and fellow students through live
//           sessions, forums, and collaborative projects.
//         </span>
//       </div>
//       <div className="box">
//         <span className="boxtopic">Certification:</span>
//         <span className="boxContent">
//           Earn recognized certificates and credentials that boost your
//           career prospects
//         </span>
//       </div>
//     </div>
//   </div>
//   <div className="offer">
//     <div className="offerTitle">Our Offerings</div>
//     <div className="offerFlexBox">
//       <div className="offerBox">
//         <div className="offerWraper">
//           <div className="icon">
//             <HiStatusOnline />
//           </div>
//           <div className="offerminiTitle">Online Courses</div>
//         </div>
//         <div className="offerText">
//           Dive into our extensive library of online courses, each crafted
//           to provide practical knowledge and skills.
//         </div>
//       </div>
//       <div className="offerBox">
//         <div className="offerWraper">
//           <div className="icon">
//             <RiPresentationLine />
//           </div>
//           <div className="offerminiTitle">Live Webinars</div>
//         </div>
//         <div className="offerText">
//           Join live sessions led by industry experts, where you can ask
//           questions and interact in real-time.
//         </div>
//       </div>
//       <div className="offerBox">
//         <div className="offerWraper">
//           <div className="icon">
//             <SiFuturelearn />
//           </div>
//           <div className="offerminiTitle">Personalized Learning</div>
//         </div>
//         <div className="offerText">
//           Customize your learning path with personalized recommendations
//           and progress tracking.
//         </div>
//       </div>
//       <div className="offerBox">
//         <div className="offerWraper">
//           <div className="icon">
//             <HiUserGroup />
//           </div>
//           <div className="offerminiTitle">Community</div>
//         </div>
//         <div className="offerText">
//           Connect with a community of learners, share experiences, and
//           grow together
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="colorArea">
//   <div className="mission">
//     <div className="missionTopic">Our Mission</div>
//     <div className="missionFlex">
//       <span className="missionText">
//         At Rays our mission is to make quality education accessible to
//         everyone, breaking down barriers to learning and fostering a
//         culture of continuous growth and development. We believe that
//         education is the key to unlocking human potential and driving
//         positive change in the world.
//       </span>
//       <div className="missionRight">
//         <span className="missionRightText">Ready to Get Started?</span>
//         <ul>
//           <li className="missionList">Explore Our Courses</li>
//           <li className="missionList">Join a Webinar</li>
//           <li className="missionList">Create Your Free Account</li>
//         </ul>
//       </div>
//     </div>
//     <p className="center">
//       Join us on this exciting journey of discovery, growth, and
//       achievement. Let's transform your aspirations into reality together.
//     </p>
//     {/* <div className="explorebtn">Explore</div>crouselboxid */}
//     <div className="explorebtn">
//       <a href="#crouselboxid">Explore</a>
//     </div>
//     {/* <div className="explorebtn">Explore</div> */}
//   </div>
// </div>

//       <div className="allCards">
//         <Cards />
//         <Cards />
//         <Cards />
//       </div>
//       <CarouselData />
//       {/* </div> */}
//     </>
//   );
// };

// export default About;
// import { heroFour as hero } from "@/data/hero";
import { Link } from "react-router-dom";
import vedio from "./features-video.mp4";
import { HiStatusOnline, HiUserGroup } from "react-icons/hi";
import { RiPresentationLine } from "react-icons/ri";
import { SiFuturelearn } from "react-icons/si";
import "./_abhero.scss";
import CarouselData from "../carousel/CarouselData.js";
import "./_service.scss";
import "./about.css";
import ServiceOneImageOne from "../../imgs/icon/skill-1.webp";
import ServiceOneImageTwo from "../../imgs/icon/skill-2.webp";
import ServiceOneImageThree from "../../imgs/icon/skill-3.webp";
import ServiceOneImageFour from "../../imgs/icon/skill-4.webp";

export default function HeroFour() {
  const hero = {
    title: {
      sliceOne: "Global leader in learning and instruction",
      sliceTwo: "Marketplace",
    },
    //   descriptionOne:
    //     "Inspiring courses for the life you want to lead. Discover our fresh approach to a life-changing educational adventure.",
    //   descriptionTwo:
    //     "We believe in putting our clients at the heart of everything we do. Your success is our success, and we are committed to delivering exceptional service tailored to your unique.",
    descriptionOne:
      "Student Rays: Illuminating the path to academic excellence. Discover our innovative approach to personalized online tutoring and witness a transformative educational journey.",

    descriptionTwo:
      "At Student Rays, we believe in empowering every learner. Our expert-led platform offers tailored academic support to students of all ages and backgrounds, helping them confidently achieve their learning goals and unlock their full potential.",
  };
  const data = {
    title: {
      sliceOne: "Why Choose ",
      sliceTwo: "Rays?",
    },
    services: [
      {
        icon: ServiceOneImageTwo,
        title: "Quality Education",
        description:
          "Our courses are designed by experts and delivered by industry professionals to ensure you receive a top-notch education.",
        animation: { name: "fade-slide bottom" },
        classNames: "border-b1",
      },
      {
        icon: ServiceOneImageOne,
        title: "Flexible Learning",
        description:
          " Learn at your own pace, on your schedule. We offer a range of courses that fit your lifestyle.",
        animation: { name: "fade-slide top" },
        classNames: "border-b2 mt-md-5",
      },
      {
        icon: ServiceOneImageThree,
        title: "Diverse Course Catalog",
        description:
          "Explore a wide variety of subjects, from arts and sciences to technology and business.",
        animation: { name: "fade-slide bottom" },
        classNames: "border-b1",
      },
      {
        icon: ServiceOneImageFour,
        title: "Interactive Learning",
        description:
          "Engage with instructors and fellow students through live sessions, forums, and collaborative projects.",
        animation: { name: "fade-slide top" },
        classNames: "border-b2 mt-md-5",
      },
    ],
  };

  return (
    <>
      <section className="abhero__area" id="about">
        <h1>About Us</h1>
        <div className="container-md">
          <div className="row">
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className="abhero__left">
                {/* <ul className="breadcrumbs">
                <li className="breadcrumbs-list">
                  <Link className="breadcrumbs-item" to="#">
                    Home
                  </Link>
                </li>
                <li className="breadcrumbs-list">
                  <Link className="breadcrumbs-item" to="#">
                    About
                  </Link>
                </li>
              </ul> */}

                {hero.title && (
                  <h1 className="abhero__title move-line-3d">
                    {hero.title.sliceOne}
                    <span className="abhero__title-shape">
                      {hero.title.sliceTwo}
                    </span>
                  </h1>
                )}
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="abhero__right">
                {hero.descriptionOne && (
                  <p className="abhero__content  move-line-3d" data-delay="0.6">
                    {hero.descriptionOne}
                  </p>
                )}
                {hero.descriptionTwo && (
                  <p className="abhero__content  move-line-3d" data-delay="0.8">
                    {hero.descriptionTwo}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="abhero__video-wrap">
                <video
                  className="abhero__video"
                  src={vedio}
                  // src="./features-video.mp4"
                  loop
                  preload="auto"
                  muted
                  autoPlay
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="selfDiscription">
            {/* Rays is an innovative educational platform dedicated to providing
          personalized, expert-led online tutoring and academic support to
          students of all ages and backgrounds, helping them achieve their
          learning goals with confidence and success. */}
          </div>
          {/* <div className="grayBox">
          <div className="chooseRays">
            <div className="seeQuestion"> Why Choose Rays?</div>
            <div className="box">
              <span className="boxtopic">Quality Education:</span>
              <span className="boxContent">
                Our courses are designed by experts and delivered by industry
                professionals to ensure you receive a top-notch education.
              </span>
            </div>
            <div className="box">
              <span className="boxtopic">Flexible Learning:</span>
              <span className="boxContent">
                Learn at your own pace, on your schedule. We offer a range of
                courses that fit your lifestyle.
              </span>
            </div>
            <div className="box">
              <span className="boxtopic">Diverse Course Catalog:</span>
              <span className="boxContent">
                Explore a wide variety of subjects, from arts and sciences to
                technology and business.
              </span>
            </div>
            <div className="box">
              <span className="boxtopic">Interactive Learning:</span>
              <span className="boxContent">
                Engage with instructors and fellow students through live
                sessions, forums, and collaborative projects.
              </span>
            </div>
            <div className="box">
              <span className="boxtopic">Certification:</span>
              <span className="boxContent">
                Earn recognized certificates and credentials that boost your
                career prospects
              </span>
            </div>
          </div>
        </div> */}

          <section className="service__area pt-110 pb-110">
            {/* <div className="container"> */}
            <div className="row">
              <div className="col-xl-12">
                <div className="service__title-wrap">
                  {data.title && (
                    <h2 className="section-title move-line-3d">
                      {data.title.sliceOne} <span>{data.title.sliceTwo}</span>
                    </h2>
                  )}
                </div>
              </div>
            </div>
            {data.services && data.services.length && (
              <div className="row gy-4">
                {data.services.map((service, index) => (
                  <div
                    key={index}
                    className={`col-xl-3 col-lg-3 col-md-6 ${service.animation.name}`}
                  >
                    <div className={`service__item ${service.classNames}`}>
                      <div className="service__icon">
                        {service.icon && <img src={service.icon} alt="Icon" />}
                      </div>
                      <h3 className="service__title">{service.title}</h3>
                      {service.description && <p>{service.description}</p>}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* </div> */}
          </section>

          <div className="offer">
            <div className="offerTitle">Our Offerings</div>
            <div className="offerFlexBox">
              <div className="offerBox">
                <div className="offerWraper">
                  <div className="icon">
                    <HiStatusOnline />
                  </div>
                  <div className="offerminiTitle">Online Courses</div>
                </div>
                <div className="offerText">
                  Dive into our extensive library of online courses, each
                  crafted to provide practical knowledge and skills.
                </div>
              </div>
              <div className="offerBox">
                <div className="offerWraper">
                  <div className="icon">
                    <RiPresentationLine />
                  </div>
                  <div className="offerminiTitle">Live Webinars</div>
                </div>
                <div className="offerText">
                  Join live sessions led by industry experts, where you can ask
                  questions and interact in real-time.
                </div>
              </div>
              <div className="offerBox">
                <div className="offerWraper">
                  <div className="icon">
                    <SiFuturelearn />
                  </div>
                  <div className="offerminiTitle">Personalized Learning</div>
                </div>
                <div className="offerText">
                  Customize your learning path with personalized recommendations
                  and progress tracking.
                </div>
              </div>
              <div className="offerBox">
                <div className="offerWraper">
                  <div className="icon">
                    <HiUserGroup />
                  </div>
                  <div className="offerminiTitle">Community</div>
                </div>
                <div className="offerText">
                  Connect with a community of learners, share experiences, and
                  grow together
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="colorArea">
          <div className="mission">
            <div className="missionTopic">Our Mission</div>
            <div className="missionFlex">
              <span className="missionText">
                At Rays our mission is to make quality education accessible to
                everyone, breaking down barriers to learning and fostering a
                culture of continuous growth and development. We believe that
                education is the key to unlocking human potential and driving
                positive change in the world.
              </span>
              <div className="missionRight">
                <span className="missionRightText">Ready to Get Started?</span>
                <ul>
                  <li className="missionList">Explore Our Courses</li>
                  <li className="missionList">Join a Webinar</li>
                  <li className="missionList">Create Your Free Account</li>
                </ul>
              </div>
            </div>
            <p className="center">
              Join us on this exciting journey of discovery, growth, and
              achievement. Let's transform your aspirations into reality
              together.
            </p>
            {/* <div className="explorebtn">Explore</div>crouselboxid */}
            <div className="explorebtn">
              <a href="#crouselboxid">Explore</a>
            </div>
            {/* <div className="explorebtn">Explore</div> */}
          </div>
        </div>
      </section>
      <CarouselData />
    </>
  );
}
