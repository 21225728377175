import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import "./dropDown.scss";

import { Outlet, Link } from "react-router-dom";
function DropDown() {
  return (
    <div className="navbartopp">
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
       

              <NavDropdown
                title="Course"
                id="collapsible-nav-dropdown"
                alignRight
              >
            
                <NavDropdown.Item href="#action/3.2">
                  <DropdownSubmenu
                    href="#action/3.7"
                    lassName="subControl"
                    title="CBSE"
                  >
                    <DropdownSubmenu href="#action/3.7" title="Grade 6">
                      <NavDropdown.Item href="/view/gr6sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr6mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 7">
                      <NavDropdown.Item href="/view/gr7sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr7mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 8">
                      <NavDropdown.Item href="/view/gr8sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr8mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 9">
                      <NavDropdown.Item href="/view/gr9sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr9mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 10">
                      <NavDropdown.Item href="/view/gr10sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/gr10mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                  </DropdownSubmenu>
                </NavDropdown.Item>
              
                <NavDropdown.Item href="#action/3.1">
                  <DropdownSubmenu
                    href="#action/3.7"
                    lassName="subControl"
                    title="ICSE"
                  >
                    <DropdownSubmenu href="#action/3.7" title="Grade 6">
                      <NavDropdown.Item href="/view/icse6sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse6mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 7">
                      <NavDropdown.Item href="/view/icse7sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse7mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 8">
                      <NavDropdown.Item href="/view/icse8sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse8mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 9">
                      <NavDropdown.Item href="/view/icse9sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse9mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                    <DropdownSubmenu href="#action/3.7" title="Grade 10">
                      <NavDropdown.Item href="/view/icse10sci">
                        Science
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse10mat">
                        Maths
                      </NavDropdown.Item>
                    </DropdownSubmenu>
                  </DropdownSubmenu>
                </NavDropdown.Item>

                <NavDropdown.Item href="#action/3.3">NEET</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">IIT</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  <DropdownSubmenu
                    href="#action/3.7"
                    className="subControl"
                    title="Compe Exam"
                  >
                    <NavDropdown.Item href="#action/8.1">
                      Entrance Exams
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/8.1">
                      <DropdownSubmenu href="#action/3.7" title="Banking Exams">
                        <NavDropdown.Item href="/view/icse10sci">
                          Bank Probationary Officer (PO) Exams
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/view/icse10mat">
                          Clerical Exam
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/view/icse10mat">
                          Specialist Officer (SO) Exams
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/view/icse10mat">
                          Regional Rural Banks (RRBs) Exams
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/view/icse10mat">
                          Reserve Bank of India (RBI) Exams
                        </NavDropdown.Item>
                      </DropdownSubmenu>
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/8.1">
                      <DropdownSubmenu href="#action/3.7" title="Railway Exams">
                        <NavDropdown.Item href="/view/icse10sci">
                          Railway Recruitment Board (RRB) Exams
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/view/icse10mat">
                          <DropdownSubmenu
                            href="#action/3.7"
                            title="Common Railway Exams in India"
                          >
                            <NavDropdown.Item href="/view/icse10sci">
                              RRB NTPC
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/view/icse10mat">
                              RRB Group D
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/view/icse10mat">
                              RRB JE/SSE
                            </NavDropdown.Item>
                          </DropdownSubmenu>
                        </NavDropdown.Item>
                      </DropdownSubmenu>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/view/icse10sci">
                      Famous Competitive Exams
                    </NavDropdown.Item>
                    <DropdownSubmenu
                      href="#action/3.7"
                      title="Competitive Exams for Professional Courses"
                    >
                      <NavDropdown.Item href="/view/icse10sci">
                        RRB NTPC
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse10mat">
                        RRB Group D
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/view/icse10mat">
                        RRB JE/SSE
                      </NavDropdown.Item>
                    </DropdownSubmenu>

                    

                  </DropdownSubmenu>
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link href="#about">About Us</Nav.Link>
              <Nav.Link href="#contact">contact Us</Nav.Link> */}
            {/* </Nav> */}
{/* 
            <Nav className="littleGap">
              <Nav.Link className="btn" href="#register">
                Register
              </Nav.Link>
              <Nav.Link className="btn" eventKey={2} href="#login">
                Login
              </Nav.Link>
            </Nav> */}
          {/* </Navbar.Collapse> */}
          {/* <Outlet /> */}
        {/* </Container> */}
      </Navbar>
    </div>
  );
}

export default DropDown;
