import React from 'react'
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { userColumns } from "../datatable/datatablesource";
import Datatable from "../datatable/Datatable";
const CounsellorComplain = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <Datatable columns={userColumns}/>
      </div>
    </div>
  )
}

export default CounsellorComplain
