import React, { useState, useEffect } from "react";
import "./home.css";
import IMG2 from "./image2.jpeg";
import IMG3 from "./image3.jpg";
import page from "./page.jpeg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Contact from "../../assets/components/contact/Contact.jsx";
// import Course from "../course/Course";
import About from "../../assets/components/about/About";
// import Header from "../../assets/components/header/Header";
import Footer from "../../assets/components/footer/Footer";
import ScrollButton from "../../assets/components/scroll/Scroll";
import Navb from "../../assets/components/nav/Navb";
import Navtop from "../../assets/components/navtop/Navtop";
import { useNavigate } from "react-router-dom";
import Img1 from "./video-camera-outline.png";
import Img2 from "./light-bulb_15256120.png";
import Img3 from "./settings_3067451.png";
import Img4 from "./handshake_11773118.png";
import Img5 from "./prototype_11855678.png";
import { Helmet } from "react-helmet";
// import axios from "axios";
// import Navb from "./src/assets/components/nav/Navb";

const Home = () => {
  const navigate = useNavigate();
  const gotosession = () => {
    navigate("/register");
  };
  const [currentImage, setCurrentImage] = useState(1);

  const images = [page, IMG3, IMG2]; // Add or remove images as needed

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage % images.length) + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <Helmet>
        <title>Home - StudentRays</title>
        <meta name="description" content="Studentrays is an innovative educational platform" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="StudentRays" />
        <meta
          property="og:description"
          content="Studentrays is an innovative educational platform"
        />
        <meta property="og:url" content="https://www.studentrays.com" />
        <link rel="canonical" href="https://www.studentrays.com" />
      </Helmet>
      <div className="front" style={{ overflow: "hidden" }}>
        {/* <div className="fixed"> */}
        <div>
          <Navtop />
          <Navb />
        </div>
        <div id="home">
          <div className="wrapper-home">
            <img
              className="imgfront"
              src={images[currentImage - 1]}
              alt="img"
            />
            <div className="headerTop">
              Student Rays is your gateway to a world of limitless learning
              opportunities.
            </div>
            <div className="homeArea">
              <button className="homeAreaBtn" onClick={gotosession}>
                Book your <span className="homeAreaSpan"> free Demo</span>
              </button>
            </div>
          </div>
        </div>
        <div id="wave" />
        <div />
        <ScrollButton />
        <About />
        <div className="logoIconsContainer">
          <div className="logoIconsTitle">
            Key Features of <br />
            Online IIT JEE + CBSE + NEET Classes
          </div>
          <div className="logoIcons">
            <a className="nodecoration" href="/register">
              <div className="logoIcon">
                <img src={Img1} alt="Img1" />
                <h4>Live</h4>
                <p>Online Classes</p>
              </div>
            </a>
            <a className="nodecoration" href="/register">
              <div className="logoIcon">
                <img src={Img2} alt="Img2" />
                <h4>Instant</h4>
                <p>Doubt Clearing</p>
              </div>
            </a>

            <a className="nodecoration" href="/register">
              <div className="logoIcon">
                <img src={Img3} alt="Img3" />
                <h4>Smart</h4>
                <p>Practice Tests</p>
              </div>
            </a>

            <a className="nodecoration" href="/register">
              <div className="logoIcon">
                <img src={Img4} alt="Img4" />
                <h4>Personalised</h4>
                <p>Mentor Support</p>
              </div>
            </a>

            <a className="nodecoration" href="/register">
              <div className="logoIcon">
                <img src={Img5} alt="Img1" />
                <h4>Concept</h4>
                <p>Video Lectures</p>
              </div>
            </a>
          </div>
        </div>
        {/* <Contact /> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
