import React, { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import "./Quest.css";

const Quest = ({ myQuestion, onTryAgain }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(myQuestion.length).fill(""));
  const [totalScore, setTotalScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [triedAgain, setTriedAgain] = useState(false); // New state to track if user has tried again

  const currentQuestion = myQuestion[currentQuestionIndex];

  const handleNextClick = () => {
    if (answers[currentQuestionIndex] !== "") {
      if (currentQuestionIndex < myQuestion.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        calculateTotalScore();
        setSubmitted(true);
      }
    }
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = value; // Update the current answer
    setAnswers(newAnswers);
  };

  const calculateTotalScore = () => {
    let newTotalScore = answers.reduce((score, answer, index) => {
      return answer === myQuestion[index].correctAnswer ? score + 1 : score;
    }, 0);

    setTotalScore(newTotalScore);
  };

  const handleTryAgain = () => {
    setTriedAgain(true); // Update state to indicate user has tried again
    onTryAgain(); // Call the parent function to fetch new questions
    setCurrentQuestionIndex(0); // Reset the question index
    setAnswers(Array(myQuestion.length).fill("")); // Reset the answers
    setTotalScore(0); // Reset the score
    setSubmitted(false); // Reset the submitted state
  };

  const isButtonDisabled = answers[currentQuestionIndex] === "";

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h2 className="display-4">Quiz</h2>
      </div>
      <div className="card p-4 shadow">
        {currentQuestion && (
          <div>
            <p className="lead" style={{ fontSize: "1.5rem" }}>
              {currentQuestion.question}
            </p>
            <ul className="list-group"  style={{ "--bs-list-group-border-color": "none" }}>
              {currentQuestion.options.map((option, index) => (
                <li className="list-group-item" key={index}>
                  <label style={{ fontSize: "1.4rem" }}>
                    <input
                      className="form-check-input"
                      style={{ marginRight: "0.25rem" }}
                      type="radio"
                      name="options"
                      value={option}
                      checked={answers[currentQuestionIndex] === option}
                      onChange={handleOptionChange}
                      required
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}

        {submitted ? (
          <div className="mt-4 text-center">
            <p className="h5">You have scored: {totalScore}</p>
            {triedAgain ? null : ( // Conditionally render the button
              <div className="h6">You can try again</div>
            )}
            <button className="btn btn-primary" onClick={handleTryAgain}>
              Try Again
            </button>
          </div>
        ) : (
          <div className="text-center mt-4">
            <button
              className="btn btn-success"
              onClick={handleNextClick}
              disabled={isButtonDisabled}
            >
              {currentQuestionIndex === myQuestion.length - 1 ? "Submit" : "Next"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quest;



// import React, { useState } from "react";
// // import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
// import "./Quest.css";

// function Quest(props) {
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [answers, setAnswers] = useState(Array(5).fill(""));
//   const [questions, setQuestions] = useState(props.myQuestion);
//   const [totalScore, setTotalScore] = useState(0);
//   const [submitted, setSubmitted] = useState(false);

//   const currentQuestion = questions[currentQuestionIndex];
//   const resetQuiz = () => {
//     setCurrentQuestionIndex(0);
//     setAnswers(Array(5).fill(""));
//     setTotalScore(0);
//     setSubmitted(false);
//   };

//   const handleNextClick = () => {
//     if (answers[currentQuestionIndex] !== "") {
//       if (currentQuestionIndex < questions.length - 1) {
//         setCurrentQuestionIndex(currentQuestionIndex + 1);
//       } else {
//         calculateTotalScore();
//         setSubmitted(true);
//       }
//     }
//   };

//   const handleOptionChange = (e) => {
//     const { value } = e.target;
//     answers[currentQuestionIndex] = value;
//     setAnswers([...answers]);
//   };

//   const calculateTotalScore = () => {
//     let newTotalScore = 0;

//     for (let i = 0; i < answers.length; i++) {
//       const selectedOption = answers[i];
//       const question = questions[i];
//       if (selectedOption === question.correctAnswer) {
//         newTotalScore += 1;
//       }
//     }

//     setTotalScore(newTotalScore);
//   };

//   const isButtonDisabled = answers[currentQuestionIndex] === "";

//   return (
//     <div className="container mt-5">
//       <div className="text-center mb-4">
//         <h2 className="display-4">Quiz</h2>
//       </div>
//       <div className="card p-4 shadow">
//         {currentQuestion && (
//           <div>
//             <p className="lead" style={{ "font-size": "1.5rem" }}>
//               {currentQuestion.question}
//             </p>
//             <ul
//               className="list-group"
//               style={{ "--bs-list-group-border-color": "none" }}
//             >
//               {currentQuestion.options.map((option, index) => (
//                 <li className="list-group-item" key={index}>
//                   <label style={{"font-size":"1.4rem"}}>
//                     <input
//                       className="form-check-input"
//                       style={{ "margin-right": "0.25rem" }}
//                       type="radio"
//                       name="options"
//                       value={option}
//                       checked={answers[currentQuestionIndex] === option}
//                       onChange={handleOptionChange}
//                       required // Make input required
//                     />
//                     {option}
//                   </label>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}

//         {submitted ? (
//           <div className="mt-4 text-center">
//             <p className="h5">You have scored: {totalScore}</p>
//             <p className="h6">You can try again</p>
//             <button className="btn btn-primary" onClick={resetQuiz}>
//               Try Again
//             </button>
//           </div>
//         ) : (
//           <div className="text-center mt-4">
//             <button
//               className="btn btn-success"
//               onClick={handleNextClick}
//               disabled={isButtonDisabled}
//             >
//               {currentQuestionIndex === questions.length - 1
//                 ? "Submit"
//                 : "Next"}
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Quest;
