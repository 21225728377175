import React, { useEffect, useState } from "react";
import axios from "axios";

function Sitemap() {
  const [sitemap, setSitemap] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    
    window.location.href = "https://api.studentrays.com/sitemap.xml";
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Sitemap</h1>
      {sitemap ? (
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{sitemap}</pre>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Sitemap;
